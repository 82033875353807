import React from 'react'

import { Link } from 'gatsby'

import * as styles from './styles.module.scss'

const BlogCard = ({ src, type, title, titleRu, text, textRu, date, href }) => {
  const themeStyles = {
    developement: '#3978F4',
    career: '#EA1896',
    technologies: '#FE7429',
    news: '#FFD600',
    ourstory: '#00CE69',
  }

  const cropText = (text) => {
    let symbolsAmount
    if (typeof window !== 'undefined' && window.innerWidth < 769) {
      symbolsAmount = 80
    } else {
      symbolsAmount = 120
    }

    if (text?.length > symbolsAmount) {
      return text?.substr(0, symbolsAmount) + '...'
    }
    return text
  }

  const currentLanguage =
    typeof window !== 'undefined' && JSON.parse(localStorage.getItem('lang'))

  return (
    <Link to={href} className={styles.blogCard}>
      <div className={styles.blogCardImage}>
        <img src={src} alt={title} />
      </div>
      <div className={styles.blogCardInner}>
        <span
          className={styles.blogCardType}
          style={{ color: themeStyles[type] }}
        >
          #{type.toLowerCase()}
        </span>
        {currentLanguage && currentLanguage === 'russian' ? (
          <h3
            className={styles.blogCardTitle}
            dangerouslySetInnerHTML={{ __html: titleRu }}
          />
        ) : (
          <h3
            className={styles.blogCardTitle}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {currentLanguage && currentLanguage === 'russian' ? (
          <p
            className={styles.blogCardText}
            dangerouslySetInnerHTML={{ __html: cropText(textRu) }}
          />
        ) : (
          <p
            className={styles.blogCardText}
            dangerouslySetInnerHTML={{ __html: cropText(text) }}
          />
        )}
        <div className={styles.blogCardDate}>{date}</div>
      </div>
    </Link>
  )
}

export default BlogCard

// extracted by mini-css-extract-plugin
export var blog = "styles-module--blog--28FGD";
export var blogModal = "styles-module--blog-modal--e8D-T";
export var blogModalWrapper = "styles-module--blog-modal-wrapper--kENTw";
export var blogModalWrapperClose = "styles-module--blog-modal-wrapper-close--rR6Y6";
export var blogModalWrapperContainer = "styles-module--blog-modal-wrapper-container--+WSx-";
export var blogModalWrapperContainerTitle = "styles-module--blog-modal-wrapper-container-title--Hai1j";
export var blogModalWrapperContainerCheck = "styles-module--blog-modal-wrapper-container-check--9GCFL";
export var blogModalWrapperContainerButton = "styles-module--blog-modal-wrapper-container-button--aGDbJ";
export var blogModalWrapperContainerButtonSelect = "styles-module--blog-modal-wrapper-container-button-select--SvTI+";
export var blogModalWrapperContainerLang = "styles-module--blog-modal-wrapper-container-lang--1-9XL";
export var blogTop = "styles-module--blog-top--mKeD0";
export var blogTitle = "styles-module--blog-title--pcpzi";
export var blogThemes = "styles-module--blog-themes--H0XCH";
export var blogThema = "styles-module--blog-thema--+mM3S";
export var blogThemaActive = "styles-module--blog-thema-active--h4XFa";
export var activeBtn = "styles-module--activeBtn--vvy9Q";
export var blogInner = "styles-module--blog-inner--+hyni";
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>({
  root: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',

    '& ul > li:first-child': {
      backgroundColor: '#F6F6FA',
      borderRadius: '48px',
      display:'flex',
      justifyContent:'center',
      marginRight:'13px',
      alignItems:'center',
      alignContent:'center',
      fontFamily:'Intel, sans-serif'
    },

    '& ul > li:last-child': {
      backgroundColor: '#F6F6FA',
      borderRadius: '48px',
      display:'flex',
      justifyContent:'center',
      color:'3978F4',
      alignItems:'center',
      alignContent:'center',
      marginLeft:'13px',
      fontFamily:'Intel, sans-serif'
    },

    '& li': {
      width: '42px',
      height: '42px',
      margin:'0 3px'
    },

    '& li:nth': {
      width: '42px',
      height: '42px',
    },

    '& button': {
      width: '42px',
      height: '42px',
      padding:'0',
      borderRadius: '48px',
      fontWeight:'500',
      fontSize:'14px',
      margin:'0',
      fontStyle:'normal',
      fontFamily:'Intel, sans-serif'
    },

    '& li:hover': {
      width: '42px',
      height: '42px',
    },

    '& .MuiPaginationItem-icon': {
      color:'#3978F4',
      fontWeight:'300',
    },

    '& .MuiPaginationItem-textPrimary.Mui-selected' : {
      backgroundColor: '#3978F4',
    },

    '& .MuiPaginationItem-page.Mui-disabled': {
      filter: 'grayscale(1)',
    }
  }})
);

import React, { useLayoutEffect, useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

import { allPostConfigurator } from '../../helpers/helpersForBlog'
import Seo from '../../components/Seo'
import Pagination from '@material-ui/lab/Pagination'
import BlogCard from '../../components/mainComponents/BlogCard/BlogCard'
import { useStyles } from '../../assets/styles/customPagination'

import * as styles from './styles.module.scss'

import blogImage from '../../assets/images/jpg/seo/Blog-min.jpg'
import close from '../../assets/images/png/blogPage/Close.png'
import russia from '../../assets/images/png/blogPage/RU.png'
import choose from '../../assets/images/png/blogPage/Vector.png'
import english from '../../assets/images/png/blogPage/ENG.png'

const paginateCards = (cardsArr, page) => [...cardsArr.slice((page - 1) * 12, page * 12)]

const createThemeText = (text) => text.slice(0, 1).toUpperCase() + text.slice(1)

const createThemesArr = (...cardsArrays) => {
  const cardsArr = [...cardsArrays].flat()
  const categorySet = new Set(cardsArr.map(({ category }) => category.toLowerCase()))
  return [...categorySet]
}

const Blog = () => {
  const dataBlog = useStaticQuery(graphql`
    {
      allContentfulBlogPost(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            id
            bodyRu {
              raw
            }
            bodyEn {
              raw
            }
            categoryRu
            categoryEn
            date
            titleEn {
              raw
            }
            titleRu {
              raw
            }
            slug
            thumb {
              file {
                url
              }
            }
            header {
              file {
                url
              }
            }
            avatar {
              file {
                url
              }
            }
            postedBy
            postedByRu
            titleForSeo
            position
          }
        }
      }
    }
  `)
  const allPost = [...dataBlog.allContentfulBlogPost.edges]
  const All_BLOG = allPostConfigurator(allPost)

  const [page, setPage] = useState(1)
  const [open, isOpen] = useState(true)
  const [lang, setLang] = useState('')

  const countPages = Math.ceil(All_BLOG.length / 12)
  const classes = useStyles()

  const onPaginationChange = (page) => {
    setPage(page)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleChangeLanguage =  (e, key) => {
    if (key === 'russian') {
       setLang('russian')
    } else {
       setLang('english')
    }
  }

  const handleChangeIsOpen = () => isOpen(false)

  const handleChangeLanguageSet = () => {
    isOpen(false)
    localStorage.setItem('lang', JSON.stringify(lang))
  }

  const currentLanguage =
    typeof window !== 'undefined' && JSON.parse(localStorage.getItem('lang'))

  useLayoutEffect(() => {
    if (currentLanguage && currentLanguage.length > 0) {
      isOpen(false)
    }
  }, [])

  return (
    <>
      <Seo
        title={'Justice | Blog'}
        description="Justice - We take care of our staff and clients. Let us show who we are."
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        image={blogImage}
      />
      {open === true ? (
        <div className={styles.blogModal}>
          <div className={styles.blogModalWrapper}>
            <img
              onClick={handleChangeIsOpen}
              src={close}
              className={styles.blogModalWrapperClose}
              alt="close"
            />
            <div className={styles.blogModalWrapperContainer}>
              <p className={styles.blogModalWrapperContainerTitle}>Select a language</p>
              <div>
                {lang && lang === 'russian' ? (
                  <div className={styles.blogModalWrapperContainerLang}>
                    <button onClick={(e) => handleChangeLanguage(e, 'russian')}>
                      <div className={styles.blogModalWrapperContainerCheck}>
                        <div>
                          <img src={russia} alt="Russian language" />
                          <p>Russia</p>
                        </div>
                        <div>
                          <img src={choose} alt="Choose language" />
                        </div>
                      </div>
                    </button>
                  </div>
                ) : (
                  <button onClick={(e) => handleChangeLanguage(e, 'russian')}>
                    <div className={styles.blogModalWrapperContainerCheck}>
                      <div>
                        <img src={russia} alt="Russian language" />
                        <p>Russia</p>
                      </div>
                      <div>
                        <img src={choose} alt="Choose language" />
                      </div>
                    </div>
                  </button>
                )}
              </div>
              <div>
                {lang && lang === 'english' ? (
                  <div className={styles.blogModalWrapperContainerLang}>
                    <button onClick={(e) => handleChangeLanguage(e, 'english')}>
                      <div className={styles.blogModalWrapperContainerCheck}>
                        <div>
                          <img src={english} alt="English language" />
                          <p>English</p>
                        </div>
                        <div>
                          <img src={choose} alt="Choose language" />
                        </div>
                      </div>
                    </button>
                  </div>
                ) : (
                  <button onClick={(e) => handleChangeLanguage(e, 'english')}>
                    <div className={styles.blogModalWrapperContainerCheck}>
                      <div>
                        <img src={english} alt="English language" />
                        <p>English</p>
                      </div>
                      <div>
                        <img src={choose} alt="Choose language" />
                      </div>
                    </div>
                  </button>
                )}
              </div>
              <div className={styles.blogModalWrapperContainerButton}>
                <button className={styles.blogModalWrapperContainerButtonSelect} onClick={handleChangeLanguageSet}>Select</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={styles.blog}>
        <div className="container">
          <div className={styles.blogTop}>
            <h2 className={styles.blogTitle}>Blog</h2>
            <div className={styles.blogThemes}>
              {createThemesArr(All_BLOG).map((text, id) =>
                  <Link
                    to={`/blog/${text}/`}
                    className={styles.blogThema}
                    key={id}
                  >
                    {createThemeText(text)}
                  </Link>
                )
              }
            </div>
          </div>
          <div className={styles.blogInner}>
            {paginateCards(All_BLOG, page).map((elem) => (
              <BlogCard
                key={elem.href + elem.title}
                title={elem.title}
                titleRu={elem.titleRu}
                href={elem.href}
                src={elem.imgSrc}
                date={elem.data}
                text={elem.description}
                textRu={elem.descriptionRu}
                type={elem.category}
                typeRu={elem.categoryRu}
              />
            ))}
          </div>
          <Pagination
            color="primary"
            className={classes.root}
            count={countPages}
            onChange={(e, page) => onPaginationChange(page)}
            defaultPage={page}
            siblingCount={0}
            classes={{selected:classes}}
          />
        </div>
      </div>
    </>
  )
}

export default Blog
